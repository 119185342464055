<template>
  <v-container class="mt-5">
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1>Vereine</h1>
          </v-col>
          <v-col cols="auto">
            <v-btn large rounded color="primary" @click="create">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Verein erstellen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          filled
          rounded
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="vereine.data"
          item-key="name"
          show-select
          class="elevation-5 rounded-xl"
          fixed-header
          :search="search"
          locale="de-DE"
          :footer-props="{
            itemsPerPageText: 'Zeilen pro Seite:',
          }"
        >
          <template v-slot:no-data>
            Es wurden keine Vereine gefunden.
          </template>
          <template v-slot:item.optionen="{ index, item }">
            <v-btn color="primary" rounded :to="`/admin/vereine/${item.id}`">
              <font-awesome-icon class="mr-1" icon="fa-solid fa-pen-to-square" />
              Bearbeiten
            </v-btn>
            <v-btn class="ml-2" color="secondary" rounded 
              @click="
                show_info = true 
                show_item = item
              ">
                <font-awesome-icon class="mr-1" icon="fa-solid fa-qrcode" />
                Angebot einreichen
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="show_info" content-class="rounded-xl" overlay-opacity="0.85" max-width="650">
      <v-card class="rounded-xl primary--text text-center pa-4">
        <v-row justify="center">
          <v-col cols="12" md="10">
            <h2>Über diesen Link können Mitarbeiter des Vereins Angebote einreichen:</h2>
          </v-col>
          <v-col cols="12" md="auto">
            <VueQRCodeComponent 
                :text="'https://finde-deinen-sport.app/'+show_item.id+'/angebot-einreichen'"
                :size="$vuetify.breakpoint.mdAndUp ? 400 : 250"
                error-level="L">
            </VueQRCodeComponent>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field readonly rounded filled :value="'https://finde-deinen-sport.app/'+show_item.id+'/angebot-einreichen'"></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Verein from './verein.vue'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import router from '../../../router'
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: 'Verein',
  data() {
    return {
      selected: [],
      search: '',
      show_info: false,
      show_item: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Kurz',
          value: 'kurz',
          sortable: true,
        },
        {
          text: 'Optionen',
          value: 'optionen',
          sortable: false,
        },
      ],
      sportangebote: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
    }),
  },
  components: {
    Verein,
    VueQRCodeComponent
  },
  methods: {
    async create() {
      const docRef = await addDoc(collection(this.db, 'Vereine'), {
        name: '',
        kurz: '',
        active: true,
        created_at: serverTimestamp(),
        created: true,
        beschreibung: '',
        farben: {
          primary: '',
          secondary: '',
        },
      })
      router.push('/admin/vereine/' + docRef.id)
    },
  },
}
</script>
